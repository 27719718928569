.nav-link.active {
  font-weight: bold;
}

input:focus {
  outline: none;
}

.day {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: #dddddd solid;
  border-width: 1px 0 0 1px;
  padding: 0.5em;
  //margin: 0 1em 1em 0;
  border-radius: 0.2em;
  background: #f8f9fa;
  line-height: 1.1em;

}


.name-box {
  height: 25px;
  width: 100%;
  display: inline-block;
  border-width: 0 0 1px 0;
  border-style: solid;
  background: transparent;
  border-color: #dddddd;
  font-weight: 200;
  width: 100%;
  font-size: 0.9em;
}



div.name {
  padding-right: 0.2em;
}

span.name-box {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding: 3px 1px 1px 2px;
}


.day h3 {
  text-align: center;
  color: rgba(90, 90, 90, 0.73);
  font-weight: 200;
}


.today h3 {
  font-weight: 500;

}

.weekend h3 {
  text-align: center;
  color: rgba(255, 0, 0, 0.65);
}


//div.row:hover {
//  background-color: #f1f1f1;
//}

div.free:hover {
  background-color: #e7fce7;
}
/*td.busy:hover {*/
/*  background-color: #fce7e7;*/
/*}*/


span.hours {
  display:inline-block;
  font-size: 0.8em;
  padding-right:0.5em;
  padding-top: 4px;
}



.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters .col,
.no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}



.react-confirm-alert-body {
  font-family: Helvetica, Arial, sans-serif;
  width: 300px;
  padding: 15px;
  text-align: justify-all;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  font-size: 0.8em;
  h1 {
    font-size: 2em;
  }
}