// Override default variables before the import

$grid-gutter-width: 8px;
$grid-columns: 24;
$container-max-widths: (
        sm: 540px,
        md: 800px,
        lg: 1080px,
        xl: 1600px
);

$grid-breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 500px,
  // Medium screen / tablet
        md: 700px,
  // Large screen / desktop
        lg: 900px,
  // Extra large screen / wide desktop
        xl: 1400px
);


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './custom.scss';


